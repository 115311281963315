import { Stack, SwipeableDrawer, TextField, Typography } from "@mui/material";
import React from "react";
import { Assets } from "../../assets";
import { CENTER, Colors } from "../../utils/utilities";

export const Connect = (props: { isOpen: boolean; onClose: any; onOpen: any; data: any }) => {
    return (
        <React.Fragment>
            <SwipeableDrawer
                container={window.document.body}
                anchor="bottom"
                open={props.isOpen}
                onClose={props.onClose}
                onOpen={props.onOpen}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <div style={{ width: '100%', backgroundColor: Colors.SUBMAIN }}>
                    <Stack direction={'column'} alignItems={'center'} spacing={3} width={'100%'} paddingY={3}>
                        <img style={{ width: 60, height: 60, objectFit: 'contain', borderRadius: 15 }} src={'https://img.freepik.com/free-vector/new-2023-twitter-logo-x-icon-design_1017-45418.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1724112000&semt=ais_hybrid'}></img>
                        <Typography variant="h5" color={'#fff'}>Connect X?</Typography>
                        <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ border: '1px solid #ffffff50', borderRadius: 50, padding: '5px 10px' }}>
                            <img src={Assets.icon.drxLogo} style={{ width: 20, height: 'auto', aspectRatio: 1, objectFit: 'contain' }} alt="" />
                            <Typography variant="body2" color={Colors.MAIN}>+1000 Points</Typography>
                        </Stack>
                        <Stack direction={'column'} alignItems={'flex-start'} spacing={1} sx={{ border: '1px solid #ffffff50', borderRadius: 3, padding: '15px 10px', width: '80%' }}>
                            <Typography variant="body2" color={Colors.MAIN}>Your ID:</Typography>
                            <TextField
                                placeholder={"Type your ID..."}
                                fullWidth
                                variant="outlined"
                                size={'small'}
                                InputProps={{
                                    sx: { backgroundColor: '#595959', borderRadius: 3, color: '#fff' }
                                }}
                            />
                        </Stack>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={1} width={'100%'}>
                            <div style={{ width: '40%', ...CENTER, padding: '10px 0', borderRadius: 10 }} onClick={props.onClose}>
                                <Typography variant="body1" color="#ffffff80">Dismiss</Typography>
                            </div>
                            <div style={{ width: '40%', ...CENTER, padding: '10px 0', borderRadius: 10, backgroundColor: Colors.MAIN }}>
                                <Typography variant="body1" color={Colors.SUBMAIN}>Submit</Typography>
                            </div>
                        </Stack>
                    </Stack>
                </div>
            </SwipeableDrawer>
        </React.Fragment>
    )
}