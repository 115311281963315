import { Stack, Typography } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router';
import { Assets } from '../../assets';
import { BottomNavigation } from '../../components/navigation/bottomBar';
import { CompleteCard } from '../../components/quest/completeCard';
import { MenuTabs } from '../../components/quest/menuTabs';
import { QuestCard } from '../../components/quest/questCard';
import { Connect } from '../../components/referral/connect';
import RouterTransitions from '../../routes/routerTransition';
import { CENTER, Colors } from '../../utils/utilities';

const QuestPage = () => {
    const navigate = useNavigate()
    const [ActiveTab, setActiveTab] = React.useState(0)
    const [isConnect, setConnect] = React.useState(false)

    const onConnect = React.useCallback((state: any) => {
        setConnect(state)
    }, [isConnect])

    const onCommunity = () => {
        window.open('https://t.me/DRXerc20', '_blank')?.focus()
    }

    return (
        <div style={{ ...CENTER, width: '100%' }}>
            <div style={{
                width: '100%',
                maxWidth: '768px',
                backgroundColor: '#000',
                backgroundImage: `url(${Assets.image.background2})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundAttachment: 'fixed',
                WebkitBackgroundSize: 'cover'
            }}>
                <div style={{ width: '100%', ...CENTER, backgroundColor: '#00000050', minHeight: '100vh', position: 'relative' }}>
                    <Stack direction={'column'} sx={{ width: '90%' }} alignItems={'center'} paddingY={5} spacing={5}>
                        <Typography variant="h5" color="#fff">Quest</Typography>
                        <img src={Assets.image.chest} style={{ width: '50%', height: 'auto', objectFit: 'contain' }} alt="" />
                        <Typography textAlign={'center'} variant="body1" color="#fff">
                            DRX ensures unparalleled authenticity and traceability in fashion tech.
                        </Typography>
                        <MenuTabs activeTab={ActiveTab} onChangeTab={(value: number) => setActiveTab(value)} />
                        {
                            ActiveTab === 0 ?
                                <>
                                    {/* <Stack direction={'column'} spacing={1} width={'100%'}>
                                        {
                                            [...Array(5)].map((item, index) => (
                                                <QuestCard key={index} onConnect={onConnect} />
                                            ))
                                        }
                                    </Stack> */}
                                    <div style={{
                                        width: '100%',
                                        backgroundColor: '#ffffff30',
                                        backdropFilter: 'blur(5px)',
                                        WebkitBackdropFilter: 'blur(5px)',
                                        ...CENTER,
                                        padding: '20px 0',
                                        borderRadius: 20
                                    }}>
                                        <Stack direction={'column'} spacing={3} width={'90%'} alignItems={'center'}>
                                            <Typography variant="h6" textAlign={'center'} color="#fff">Quest are available Soon</Typography>
                                            <Stack
                                                direction={'row'}
                                                alignItems={'center'}
                                                spacing={1}
                                                sx={{ border: `1px solid ${Colors.MAIN}`, borderRadius: 50, padding: '8px 16px', backgroundColor: '#ffffff50', cursor: 'pointer' }}
                                                onClick={onCommunity}
                                            >
                                                <img src={Assets.icon.drxLogo} style={{ width: 25, height: 'auto', aspectRatio: 1, objectFit: 'contain' }} alt="" />
                                                <Typography variant="body2" color={"#fff"}>Our Community Here</Typography>
                                            </Stack>
                                        </Stack>
                                    </div>
                                </>
                                :
                                <div style={{
                                    width: '100%',
                                    border: `1px solid ${Colors.MAIN}`,
                                    backgroundColor: '#ffffff30',
                                    backdropFilter: 'blur(5px)',
                                    WebkitBackdropFilter: 'blur(5px)',
                                    ...CENTER,
                                    padding: '20px 0',
                                    borderRadius: 20
                                }}>
                                    {/* <Stack direction={'column'} spacing={1} width={'90%'} alignItems={'center'}>
                                        {
                                            [...Array(5)].map((item, index) => (
                                                <CompleteCard key={index} />
                                            ))
                                        }
                                    </Stack> */}
                                    <Stack direction={'column'} spacing={3} width={'90%'} alignItems={'center'}>
                                        <Typography variant="h5" textAlign={'center'} color="#fff"><b>You haven't completed any quest yet.</b></Typography>
                                        <Typography variant="body1" textAlign={'center'} color="#fff">
                                            {`Let's get started and complete yout quests!\nYour progress is important, so dive in and begin your journey now!`}
                                        </Typography>
                                        <Typography variant="body1" color={Colors.SUBMAIN} sx={{ backgroundColor: Colors.MAIN, borderRadius: 50, padding: '10px 20px' }}><b>Start Quest</b></Typography>
                                    </Stack>
                                </div>
                        }
                        <div style={{ width: '25%', height: 'auto', aspectRatio: 4 / 3 }}></div>
                    </Stack>
                </div>
                <Connect
                    isOpen={isConnect}
                    onClose={() => onConnect(false)}
                    onOpen={() => onConnect(true)}
                    data={{}}
                />
                <BottomNavigation activePage={'Quest'} navigation={navigate} />
            </div>
        </div>
    )
}

export default RouterTransitions(QuestPage);