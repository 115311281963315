import * as React from 'react';
import { Dialog, DialogContent, Stack, Zoom, Tooltip, Snackbar, Typography, Icon } from '@mui/material';
import { CENTER, Colors } from '../../utils/utilities';

export const SuccessModal = (props: { isOpen: boolean, onClose: any }) => {
    return (
        <Dialog
            PaperProps={{ style: { borderRadius: 15, padding: 1, width: '100%' } }}
            TransitionComponent={Zoom}
            open={props.isOpen}
            onClose={() => props.onClose(false)}
        >
            <DialogContent>
                <Stack direction={'column'} spacing={3} alignItems={'center'}>
                    <Icon sx={{ color: Colors.MAIN, fontSize: 50 }}>task_alt</Icon>
                    <div style={{width: '100%'}}>
                        <Typography textAlign={'center'} variant="h6" color="#000"><b>All Done!</b></Typography>
                        <Typography textAlign={'center'} variant="body1" color="#000">Referral code has been Claimed!</Typography>
                    </div>
                    <div onClick={props.onClose} style={{ ...CENTER, backgroundColor: Colors.MAIN, borderRadius: 15, padding: '10px 0', width: '50%' }}>
                        <Typography textAlign={'center'} variant="body1" color="#fff">OK</Typography>
                    </div>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export const ErrorModal = (props: { isOpen: boolean, onClose: any }) => {
    return (
        <Dialog
            PaperProps={{ style: { borderRadius: 15, padding: 1, width: '100%' } }}
            TransitionComponent={Zoom}
            open={props.isOpen}
            onClose={() => props.onClose(false)}
        >
            <DialogContent>
                <Stack direction={'column'} spacing={3} alignItems={'center'}>
                    <Icon sx={{ color: Colors.ERROR, fontSize: 50 }}>cancel</Icon>
                    <div style={{width: '100%'}}>
                        <Typography textAlign={'center'} variant="h6" color="#000"><b>Failed!</b></Typography>
                        <Typography textAlign={'center'} variant="body1" color="#000">Referral code cannot be Claimed!</Typography>
                    </div>
                    <div onClick={props.onClose} style={{ ...CENTER, backgroundColor: Colors.ERROR, borderRadius: 15, padding: '10px 0', width: '50%' }}>
                        <Typography textAlign={'center'} variant="body1" color="#fff">Close</Typography>
                    </div>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}