import { Avatar, Stack, Typography } from "@mui/material"
import { Assets } from "../../assets"
import { IRanks } from "../../interfaces"
import { CENTER, Colors, FormatCurrency } from "../../utils/utilities"

export const TopRankCard = (props: { item: IRanks; index: number }) => {
    return (
        <div style={{ width: '100%', backgroundColor: Colors.SUBMAIN, borderRadius: 20, ...CENTER }}>
            <Stack direction={'row'} alignItems={'center'} spacing={1} justifyContent={'space-between'} width={'90%'} paddingY={2}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Avatar style={{ width: 40, height: 40 }} src={props.item.photo_url}></Avatar>
                    <Stack direction={'column'} alignItems={'flex-start'} spacing={.5}>
                        <Typography variant="body1" color="#fff">{props.item.first_name + ' ' + props.item.last_name}</Typography>
                        <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ border: '1px solid #ffffff50', borderRadius: 50, padding: '3px 8px' }}>
                            <img src={Assets.icon.drxLogo} style={{ width: 20, height: 'auto', aspectRatio: 1, objectFit: 'contain' }} alt="" />
                            <Typography variant="caption" color={Colors.MAIN}>{FormatCurrency({ amount: props.item.point })}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Typography variant="h5" color={'#fff'}>#{props.index}</Typography>
            </Stack>
        </div>
    )
}