import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProfile } from "../../interfaces";

const ProfileSlice = createSlice({
  name: "Profile_Data",
  initialState: {
    data: {
      id: "",
      username: "",
      photo_url: "",
      last_name: "",
      first_name: "",
    } as IProfile,
  },
  reducers: {
    setProfileData(state, action: PayloadAction<any>) {
      state.data = action.payload.data;
    },
  },
});

export const { setProfileData } = ProfileSlice.actions;
export const ProfileData = ProfileSlice.reducer;
