import { combineReducers } from "@reduxjs/toolkit";
import { ProfileData } from "./modules/profile";

const rootReducer = combineReducers({
  profile_data: ProfileData,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
