import React from "react";
import TitlePage from "./routerTitle";
import { Routes, Route, useLocation } from "react-router-dom";

import { AnimatePresence } from "framer-motion";
import StartupPage from "../pages/startup";
import HomePage from "../pages/home";
import QuestPage from "../pages/quest";
import ReferralPage from "../pages/referral";
import RankPage from "../pages/rank";

const PageRoutes = () => {
    const location = useLocation()
    const { pathname } = useLocation();

    React.useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 500)
    }, [pathname]);

    return (

        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                <Route index element={<TitlePage title="Startup | DRX Mini Apps"><StartupPage /></TitlePage>} />
                <Route path="/home" element={<TitlePage title="Home | DRX Mini Apps"><HomePage /></TitlePage>} />
                <Route path="/quest" element={<TitlePage title="Quest | DRX Mini Apps"><QuestPage /></TitlePage>} />
                <Route path="/referral" element={<TitlePage title="Referral | DRX Mini Apps"><ReferralPage /></TitlePage>} />
                <Route path="/rank" element={<TitlePage title="Rank | DRX Mini Apps"><RankPage /></TitlePage>} />
            </Routes>
        </AnimatePresence>

    )
}

export default PageRoutes;