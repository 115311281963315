import { CircularProgress, Icon, Stack, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Assets } from '../../assets';
import { BottomNavigation } from '../../components/navigation/bottomBar';
import { ErrorModal, SuccessModal } from '../../components/referral/dialogs';
import { Invitation } from '../../components/referral/invitation';
import { MenuTabs } from '../../components/referral/menuTabs';
import Share from '../../components/referral/share';
import { IReferral } from '../../interfaces';
import RouterTransitions from '../../routes/routerTransition';
import { HTTPUserCheckReferral, HTTPUserGetReferral } from '../../servers/users';
import { RootState } from '../../stores/rootReducer';
import { CENTER, Colors } from '../../utils/utilities';

const ReferralPage = () => {
    const navigate = useNavigate()
    const [init, setInit] = React.useState(false)
    const [isInvite, setInvite] = React.useState(false)
    const [isShare, setShare] = React.useState(false)
    const [isSuccess, setSuccess] = React.useState(false)
    const [isError, setError] = React.useState(false)
    const [isLoading, setLoading] = React.useState(false)
    const ProfileRedux = useSelector((state: RootState) => state.profile_data.data)
    const [ReferralCode, setReferralCode] = React.useState<IReferral>({
        linkreferal: "",
        referal_code: ""
    })
    const [ActiveTab, setActiveTab] = React.useState(0)
    const [Referral, setReferral] = React.useState("")

    const onInvite = React.useCallback((state: any) => {
        setInvite(state)
    }, [isInvite])

    const onShare = React.useCallback((state: any) => {
        setShare(state)
    }, [isShare])

    const GetReferral = async () => {
        try {
            const result = await HTTPUserGetReferral({ user_id: ProfileRedux.id })
            setReferralCode(result.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const ClaimReferral = async () => {
        setLoading(true)
        try {
            await HTTPUserCheckReferral({ referral: Referral })
            setSuccess(true)
            setReferral("")
        } catch (error) {
            console.log(error)
            setError(true)
        }
        setLoading(false)
    }

    React.useEffect(() => {
        const Initial = () => {
            GetReferral()
        }
        Initial()
    }, [init])

    return (
        <div style={{ ...CENTER, width: '100%' }}>
            <div style={{
                width: '100%',
                maxWidth: '768px',
                backgroundColor: '#000',
                backgroundImage: `url(${Assets.image.background2})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundAttachment: 'fixed'
            }}>
                <div style={{ width: '100%', ...CENTER, backgroundColor: '#00000050', minHeight: '100vh', position: 'relative' }}>
                    <Stack direction={'column'} sx={{ width: '90%', height: '100%' }} alignItems={'center'} paddingY={5} spacing={5}>
                        <Typography variant="h5" color="#fff">Referral Points</Typography>
                        <Stack direction={'column'} spacing={3} alignItems={'center'} width='100%'>
                            <img src={Assets.icon.drxLogoText} style={{ width: '60%', height: 'auto', objectFit: 'contain' }} alt="" />
                            <MenuTabs activeTab={ActiveTab} onChangeTab={(value: number) => setActiveTab(value)} />
                            {
                                ActiveTab === 0 ?
                                    <div style={{
                                        width: '100%',
                                        border: `1px solid ${Colors.MAIN}`,
                                        backgroundColor: '#ffffff30',
                                        backdropFilter: 'blur(5px)',
                                        WebkitBackdropFilter: 'blur(5px)',
                                        ...CENTER,
                                        padding: '20px 0',
                                        borderRadius: 20
                                    }}>
                                        <Stack direction={'column'} spacing={3} width={'100%'} alignItems={'center'}>
                                            <Typography variant="h6" color="#fff" textAlign={'center'}>Embrace the future with DRX.</Typography>
                                            <Stack direction={'column'} spacing={1} width={'90%'} alignItems={'center'}>
                                                <div style={{ width: '100%', backgroundColor: Colors.SUBMAIN, borderRadius: 20, ...CENTER }}>
                                                    <Stack direction={'row'} alignItems={'center'} spacing={1} width={'90%'} paddingY={2}>
                                                        <Icon
                                                            fontSize={'medium'}
                                                            sx={{ color: Colors.MAIN, border: '1px solid #ffffff80', padding: 1, aspectRatio: 1, borderRadius: 50 }}
                                                            baseClassName="material-icons-outlined"
                                                        >share_outlined</Icon>
                                                        <Typography variant="body1" color="#fff">Share your referral link</Typography>
                                                    </Stack>
                                                </div>
                                            </Stack>
                                            <Stack direction={'column'} spacing={1} width={'90%'} alignItems={'center'}>
                                                <div style={{ width: '100%', backgroundColor: Colors.SUBMAIN, borderRadius: 20, ...CENTER }}>
                                                    <Stack direction={'row'} alignItems={'center'} spacing={1} width={'90%'} paddingY={2}>
                                                        <Icon
                                                            fontSize={'medium'}
                                                            sx={{ color: Colors.MAIN, border: '1px solid #ffffff80', padding: 1, aspectRatio: 1, borderRadius: 50 }}
                                                            baseClassName="material-icons-outlined"
                                                        >share_outlined</Icon>
                                                        <Typography variant="body1" color="#fff">
                                                            Bring your friends to DRX and start farming points together
                                                        </Typography>
                                                    </Stack>
                                                </div>
                                            </Stack>
                                            <Stack direction={'column'} spacing={1} width={'90%'} alignItems={'center'}>
                                                <div style={{ width: '100%', backgroundColor: Colors.SUBMAIN, borderRadius: 20, ...CENTER }}>
                                                    <Stack direction={'row'} alignItems={'center'} spacing={1} width={'90%'} paddingY={2}>
                                                        <Icon
                                                            fontSize={'medium'}
                                                            sx={{ color: Colors.MAIN, border: '1px solid #ffffff80', padding: 1, aspectRatio: 1, borderRadius: 50 }}
                                                            baseClassName="material-icons-outlined"
                                                        >share_outlined</Icon>
                                                        <Typography variant="body1" color="#fff">
                                                            Earn 10% from direct referrals, 5% from their referrals for each completed quest
                                                        </Typography>
                                                    </Stack>
                                                </div>
                                            </Stack>
                                            <Typography
                                                variant="body1"
                                                color={Colors.SUBMAIN}
                                                onClick={() => onInvite(true)}
                                                sx={{
                                                    backgroundColor: Colors.MAIN,
                                                    borderRadius: 50,
                                                    padding: '10px 20px',
                                                    cursor: 'pointer'
                                                }}
                                            ><b>INVITE A FRIEND</b></Typography>
                                        </Stack>
                                    </div>
                                    :
                                    <div style={{
                                        width: '100%',
                                        border: `1px solid ${Colors.MAIN}`,
                                        backgroundColor: '#ffffff30',
                                        backdropFilter: 'blur(5px)',
                                        WebkitBackdropFilter: 'blur(5px)',
                                        ...CENTER,
                                        padding: '20px 0',
                                        borderRadius: 20
                                    }}>
                                        <Stack direction={'column'} spacing={3} width={'100%'} alignItems={'center'}>
                                            <Typography variant="h6" color="#fff" textAlign={'center'}>Input the Referral Code!</Typography>
                                            <Stack
                                                direction={'column'}
                                                alignItems={'flex-start'}
                                                spacing={1}
                                                sx={{ border: '1px solid #ffffff50', borderRadius: 3, padding: '15px 10px', width: '80%', backgroundColor: Colors.SUBMAIN }}
                                            >
                                                <Typography variant="body2" color={Colors.MAIN}>Submit Referral:</Typography>
                                                <TextField
                                                    placeholder={"Referral Code..."}
                                                    fullWidth
                                                    variant="outlined"
                                                    size={'medium'}
                                                    value={Referral}
                                                    onChange={(text) => setReferral(text.target.value)}
                                                    InputProps={{
                                                        sx: { backgroundColor: '#595959', borderRadius: 3, color: Colors.MAIN, fontSize: 20 },
                                                    }}
                                                />
                                            </Stack>
                                            <div onClick={ClaimReferral} style={{ ...CENTER, padding: '10px 30px', borderRadius: 50, backgroundColor: Colors.MAIN, color: Colors.SUBMAIN }}>
                                                {
                                                    isLoading ?
                                                        <div style={{ padding: '0 15px' }}>
                                                            <CircularProgress size={20} color="inherit" />
                                                        </div>
                                                        :
                                                        <Typography variant="body1" color={Colors.SUBMAIN}>Submit</Typography>
                                                }
                                            </div>
                                        </Stack>
                                    </div>
                            }
                        </Stack>
                        <div style={{ width: '25%', height: 'auto', aspectRatio: 4 / 3 }}></div>
                    </Stack>
                </div>
                <Invitation
                    isOpen={isInvite}
                    onClose={() => onInvite(false)}
                    onOpen={() => onInvite(true)}
                    data={ReferralCode}
                    onShare={() => onShare(true)}
                />
                <Share
                    isOpen={isShare}
                    onClose={() => onShare(false)}
                    data={ReferralCode}
                />
                <BottomNavigation activePage={'Referral'} navigation={navigate} />
                <SuccessModal isOpen={isSuccess} onClose={() => setSuccess(false)} />
                <ErrorModal isOpen={isError} onClose={() => setError(false)} />
            </div>
        </div>
    )
}

export default RouterTransitions(ReferralPage);