import { AxiosMethod } from "../utils/interceptors";

const BotToken = "7379602216:AAGG8HnRTuutzM3J0EDhcX6UZPFwENUmpJA";

export function HTTPUserPicture(params: { user_id: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const phase1 = await AxiosMethod().get(
        `https://api.telegram.org/bot${BotToken}/getUserProfilePhotos?user_id=${params.user_id}`
      );
      const phase2 = await AxiosMethod().get(
        `https://api.telegram.org/bot${BotToken}/getFile?file_id=${phase1.data.result.photos[0][0].file_id}`
      );
      return resolve(
        `https://api.telegram.org/file/bot${BotToken}/${phase2.data.result.file_path}`
      );
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUserCheck(params: { user_id: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get("user/check", {
        params: {
          hash: "_drX404",
          id: params.user_id,
        },
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUserCreate(params: {
  user_id: string;
  first_name: string;
  last_name: string;
  username: string;
  photo_url: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post("user/create?hash=_drX404", {
        id: params.user_id,
        first_name: params.first_name,
        last_name: params.last_name,
        username: params.username,
        photo_url: params.photo_url,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUserGet(params: { user_id: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get("user/get", {
        params: {
          hash: "_drX404",
          id: params.user_id,
        },
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUsersGetAll(params: { page: number }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get("user/getAllUser", {
        params: {
          hash: "_drX404",
          page: params.page.toString(),
          limit: "10",
        },
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUserGetReferral(params: { user_id: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get("user/get-referral", {
        params: {
          hash: "_drX404",
          id: params.user_id,
        },
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPUserCheckReferral(params: {
  referral: string;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post("user/check-Ref?hash=_drX404", {
        refferal_code: params.referral,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
