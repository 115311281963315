import { Icon, Snackbar, Stack, SwipeableDrawer, Typography } from "@mui/material";
import React from "react";
import { Assets } from "../../assets";
import { CENTER, Colors } from "../../utils/utilities";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IReferral } from "../../interfaces";

export const Invitation = (props: { isOpen: boolean; onClose: any; onOpen: any; data: IReferral, onShare: any }) => {
    const [isCopy, setCopy] = React.useState(false)

    const onShare = () => {
        props.onClose(false)
        props.onShare()
    }

    return (
        <React.Fragment>
            <SwipeableDrawer
                container={window.document.body}
                anchor="bottom"
                open={props.isOpen}
                onClose={props.onClose}
                onOpen={props.onOpen}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <div style={{ width: '100%', backgroundColor: Colors.SUBMAIN, ...CENTER }}>
                    <Stack direction={'column'} alignItems={'center'} spacing={3} width={'100%'} paddingY={3}>
                        <Typography variant="h6" color={'#fff'}><b>INVITE A FRIEND</b></Typography>
                        <div style={{ border: '1px solid #ffffff50', borderRadius: 10, padding: '10px 0', width: '90%', ...CENTER }}>
                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={1} width={'90%'}>
                                <div>
                                    <Typography variant="body1" color={Colors.MAIN}>Share my referral code</Typography>
                                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                        <img src={Assets.icon.drxLogo} style={{ width: 30, height: 'auto', aspectRatio: 1, objectFit: 'contain' }} alt="" />
                                        <Typography variant="h6" color={'#fff'} textTransform={'uppercase'}>{props.data.referal_code}</Typography>
                                    </Stack>
                                </div>
                                <CopyToClipboard text={props.data.referal_code} onCopy={() => setCopy(true)}>
                                    <Icon
                                        fontSize={'medium'}
                                        sx={{
                                            color: Colors.MAIN,
                                            borderRadius: 50,
                                            backgroundColor: Colors.MAIN + '50',
                                            aspectRatio: 1,
                                            padding: 2,
                                            cursor: 'pointer'
                                        }}
                                    >content_copy</Icon>
                                </CopyToClipboard>
                            </Stack>
                        </div>
                        <div onClick={onShare} style={{ width: '90%', ...CENTER, padding: '15px 0', borderRadius: 50, backgroundColor: Colors.MAIN, cursor: 'pointer' }}>
                            <Typography variant="body2" color={Colors.SUBMAIN}>INVITE A FRIEND</Typography>
                        </div>
                        <CopyToClipboard text={props.data.linkreferal} onCopy={() => setCopy(true)}>
                            <div style={{ width: '90%', ...CENTER, padding: '15px 0', borderRadius: 50, backgroundColor: Colors.MAIN, cursor: 'pointer' }}>
                                <Typography variant="body2" color={Colors.SUBMAIN}>COPY LINK</Typography>
                            </div>
                        </CopyToClipboard>
                    </Stack>
                    <Snackbar
                        open={isCopy}
                        autoHideDuration={2000}
                        onClose={() => setCopy(false)}
                        message="Link has been copied"
                    />
                </div>
            </SwipeableDrawer>
        </React.Fragment>
    )
}