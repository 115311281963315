export const CENTER = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const Colors = {
  MAIN: "#e0b761",
  SUBMAIN: "#232222",
  PRIMARY: "#027bff",
  SECONDARY: "#6c757d",
  SUCCESS: "#86fa47",
  ERROR: "#ed1a23",
  WARNING: "#fef200",
  INFO: "#14a2b8",
};

export const FormatCurrency = (props: {
  amount: number;
  showCurrency?: boolean;
}) => {
  let result = "";

  result = props.amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

  if (props.showCurrency) {
    result = `Rp ${result}`;
  }

  return result;
};
