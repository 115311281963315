import * as React from 'react';
import { Router, Routes, Route } from "react-router-dom";
import { useIntegration } from '@telegram-apps/react-router-integration';
import App from './App';
import '@telegram-apps/telegram-ui/dist/styles.css';
import {
    bindMiniAppCSSVars,
    bindThemeParamsCSSVars,
    bindViewportCSSVars,
    initNavigator, useLaunchParams,
    useMiniApp,
    useThemeParams,
    useViewport,
} from '@telegram-apps/sdk-react';
import { AppRoot } from '@telegram-apps/telegram-ui';

const Init = () => {
    const lp = useLaunchParams();
    const miniApp = useMiniApp();
    // const themeParams = useThemeParams();
    // const viewport = useViewport();
    const navigator = React.useMemo(() => initNavigator('app-navigation-state'), []);
    const [location, reactNavigator] = useIntegration(navigator);

    // React.useEffect(() => {
    //     return bindMiniAppCSSVars(miniApp, themeParams);
    // }, [miniApp, themeParams]);

    // React.useEffect(() => {
    //     return bindThemeParamsCSSVars(themeParams);
    // }, [themeParams]);

    // React.useEffect(() => {
    //     return viewport && bindViewportCSSVars(viewport);
    // }, [viewport]);

    React.useEffect(() => {
        navigator.attach();
        return () => navigator.detach();
    }, [navigator]);

    return (
        <AppRoot
            appearance={miniApp.isDark ? 'dark' : 'light'}
            platform={['macos', 'ios'].includes(lp.platform) ? 'ios' : 'base'}
        >
            <Router location={location} navigator={reactNavigator}>
                <Routes>
                    <Route path="/*" element={<App />}></Route>
                </Routes>
            </Router>
        </AppRoot>
    )
}

export default Init;

