import { Assets } from "../../assets"
import { CENTER, Colors } from "../../utils/utilities"
import Typography from '@mui/material/Typography'
import { Stack } from "@mui/material"
import "./navigation.css"

export const ErrorPage = () => {
    return (
        <div style={{
            width: '100vw',
            height: '100vh',
            backgroundColor: Colors.SUBMAIN,
            ...CENTER,
            backgroundImage: `url(${Assets.image.background2})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            
      
        }}>
            <Stack
                direction={'column'}
                spacing={3}
                alignItems={'center'}
                sx={{
                    backgroundColor: '#ffffff30',
                    border: `1px solid ${Colors.MAIN}`,
                    padding: '20px 30px',
                    borderRadius: 5,
                    backdropFilter: 'blur(5px)',
                    WebkitBackdropFilter: 'blur(5px)',
                    margin: '3%'
                }}
            >
                <img src={Assets.icon.drxLogoText} style={{ width: '30vh', height: 'auto', aspectRatio: 16 / 9, objectFit: 'contain' }} alt="" />
                <Typography variant="h6" textAlign={"center"} color="#ffff">This site can be access with Telegram.</Typography>
                <a style={{ ...CENTER, width: '100%', zIndex: 5, textDecoration: 'none' }} href="https://t.me/drx_mini_bot/mini_game" target="_blank">
                    <div
                        style={{
                            ...CENTER,
                            width: '100%',
                            borderRadius: 50,
                            backgroundColor: '#00000080',
                            padding: '15px 0',
                            border: `2px solid ${Colors.MAIN}`,
                            backdropFilter: 'blur(5px)',
                            WebkitBackdropFilter: 'blur(5px)',
                        }}
                    >
                        <Typography variant="body1" color="#fff"><b>Go To Telegram!</b></Typography>
                    </div>
                </a>
            </Stack>

            
        </div>
    )
}