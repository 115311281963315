import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Init from './init';
import { SDKProvider } from '@telegram-apps/sdk-react';
import { ErrorPage } from './components/navigation/errorPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <>
    {
      window.Telegram.WebApp.initData.length === 0 ?
        <ErrorPage />
        :
        <SDKProvider acceptCustomStyles={true} debug={false}>
          <Init />
        </SDKProvider>
    }
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();