export const Images = {
  chest: require("./chest.png"),
  avatar: require("./avatar.png"),
  background1: require("./background1.png"),
  background2: require("./background2.png"),
  background3: require("./background3.png"),
  background4: require("./background4.png"),
  slide1: require("./slide1.jpg"),
  slide2: require("./slide2.jpg"),
  slide3: require("./slide3.jpg"),
};
