import * as React from 'react';
import { Dialog, DialogContent, Stack, Zoom, Tooltip, Snackbar, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper/modules";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    FacebookShareButton, FacebookIcon,
    LinkedinShareButton, LinkedinIcon,
    TelegramShareButton, TelegramIcon,
    TwitterShareButton, TwitterIcon,
    WhatsappShareButton, WhatsappIcon,
    RedditShareButton, RedditIcon,
} from "react-share";
import { IReferral } from '../../interfaces';
import { CENTER } from '../../utils/utilities';

const Share = (props: { isOpen: boolean, onClose: any, data: IReferral }) => {
    const [copySnack, setCopySnack] = React.useState(false)
    const isSnack = () => {
        setCopySnack(!copySnack)
    }

    return (
        <Dialog PaperProps={{ style: { borderRadius: 15, padding: 1, width: '100%' } }} TransitionComponent={Zoom} open={props.isOpen} onClose={() => props.onClose(false)}>
            <DialogContent>
                <Stack direction={'column'} spacing={3}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography variant="body1" color="#000"><b>Share</b></Typography>
                        <div onClick={() => props.onClose(false)} style={{ cursor: 'pointer' }}>
                            <CloseIcon sx={{ color: '#000', fontWeight: 400 }} fontSize={'medium'}></CloseIcon>
                        </div>
                    </Stack>
                    <br></br>
                    <Typography variant="body2" color="#000">Share this link via</Typography>
                    <Swiper
                        slidesPerView={4.5}
                        spaceBetween={5}
                        freeMode={true}
                        pagination={false}
                        modules={[FreeMode, Pagination]}
                        style={{ width: '100%', height: '100%' }}
                    >
                        <SwiperSlide>
                            <Stack width={80} direction={'column'} alignItems={'center'} gap={1}>
                                <WhatsappShareButton url={props.data.linkreferal} title={'Hey!, check out this Mini Games!'} separator='::'>
                                    <WhatsappIcon size={48} round />
                                </WhatsappShareButton>
                                <Typography variant="caption" color="#000">Whatsapp</Typography>
                            </Stack>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Stack width={80} direction={'column'} alignItems={'center'} gap={1}>
                                <FacebookShareButton url={props.data.linkreferal} content={'Hey!, check out this Mini Games!'}>
                                    <FacebookIcon size={48} round />
                                </FacebookShareButton>
                                <Typography variant="caption" color="#000">Facebook</Typography>
                            </Stack>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Stack width={80} direction={'column'} alignItems={'center'} gap={1}>
                                <TwitterShareButton url={props.data.linkreferal} content={'Hey!, check out this Mini Games!'}>
                                    <TwitterIcon size={48} round />
                                </TwitterShareButton>
                                <Typography variant="caption" color="#000">Twitter</Typography>
                            </Stack>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Stack width={80} direction={'column'} alignItems={'center'} gap={1}>
                                <TelegramShareButton url={props.data.linkreferal} title={'Hey!, check out this Mini Games!'}>
                                    <TelegramIcon size={48} round />
                                </TelegramShareButton>
                                <Typography variant="caption" color="#000">Telegram</Typography>
                            </Stack>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Stack width={80} direction={'column'} alignItems={'center'} gap={1}>
                                <LinkedinShareButton url={props.data.linkreferal}>
                                    <LinkedinIcon size={48} round />
                                </LinkedinShareButton>
                                <Typography variant="caption" color="#000">LinkedIn</Typography>
                            </Stack>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Stack width={80} direction={'column'} alignItems={'center'} gap={1}>
                                <RedditShareButton url={props.data.linkreferal}>
                                    <RedditIcon size={48} round />
                                </RedditShareButton>
                                <Typography variant="caption" color="#000">Reddit</Typography>
                            </Stack>
                        </SwiperSlide>
                    </Swiper>
                    <Typography variant="body2" color="#000">Or copy link</Typography>
                    <div style={{ width: '100%', borderRadius: 10, padding: 5, backgroundColor: '#dcdcdc', border: '1px solid #a8a8a8', marginTop: 20 }}>
                        <Stack alignItems={'center'} justifyContent={'space-between'} direction={'row'}>
                            <Typography variant="caption" color="#565656" whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'} margin={'0 15px'}>{props.data.linkreferal}</Typography>
                            <CopyToClipboard text={props.data.linkreferal} onCopy={isSnack}>
                                <Tooltip title="Copy to Clipboard" arrow>
                                    <div style={{ backgroundColor: '#a8a8a8', ...CENTER, borderRadius: 5, padding: '7px 15px', cursor: 'pointer' }}>
                                        <Typography variant="caption" color="#fff">Copy</Typography>
                                    </div>
                                </Tooltip>
                            </CopyToClipboard>
                        </Stack>
                    </div>
                </Stack>
                <Snackbar
                    open={copySnack}
                    autoHideDuration={2000}
                    onClose={isSnack}
                    message="Link has been copied"
                />
            </DialogContent>
        </Dialog>
    )
}

export default Share;