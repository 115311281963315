import { Box, Icon, Stack } from "@mui/material"
import { useWindowDimensions } from "../../utils/dimensions"
import { CENTER, Colors } from "../../utils/utilities"
import useDetectKeyboardOpen from "use-detect-keyboard-open";

const Icons = [
    {
        name: 'Home',
        icon: 'home_outlined',
        navigate: '/home'
    },
    {
        name: 'Quest',
        icon: 'workspace_premium_outlined',
        navigate: '/quest'
    },
    {
        name: 'Referral',
        icon: 'share_outlined',
        navigate: '/referral'
    },
    {
        name: 'Rank',
        icon: 'emoji_events_outlined',
        navigate: '/rank'
    },
]

export const BottomNavigation = (props: { activePage: string, navigation: any }) => {
    const { width } = useWindowDimensions()
    const isKeyboardOpen = useDetectKeyboardOpen();

    const onNavigate = (link: string) => {
        props.navigation(link)
    }

    return (
        <>
            {
                isKeyboardOpen ? null :
                    <div style={{
                        borderRadius: '30px 30px 0 0',
                        backgroundColor: '#ffffff30',
                        backdropFilter: 'blur(5px)',
                        WebkitBackdropFilter: 'blur(5px)',
                        width: '100%',
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0
                    }}>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                            {
                                Icons.map((item, index) => (
                                    <Box
                                        key={index}
                                        onClick={() => onNavigate(item.navigate)}
                                        sx={{
                                            height: 'auto',
                                            width: '25%',
                                            aspectRatio: width < 400 ? 4 / 3 : width > 600 ? 2 : 4 / 3,
                                            ...CENTER,
                                            color: props.activePage === item.name ? Colors.MAIN : '#fff',
                                            transition: 'all .3s',
                                            '&: hover': {
                                                backgroundColor: '#00000050',
                                                cursor: 'pointer',
                                                transition: 'all .3s',
                                            }
                                        }}
                                    >
                                        <Icon
                                            baseClassName='material-icons-outlined'
                                            sx={{ color: 'inherit', fontSize: 25 }}
                                        >{item.icon}</Icon>
                                    </Box>
                                ))
                            }
                        </Stack>
                    </div >
            }
        </>
    )
}