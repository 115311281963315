import { Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { Assets } from '../../assets';
import { BottomNavigation } from '../../components/navigation/bottomBar';
import { RankCard } from '../../components/rank/rankCard';
import { TopRankCard } from '../../components/rank/topRankCard';
import RouterTransitions from '../../routes/routerTransition';
import { HTTPUsersGetAll } from '../../servers/users';
import { CENTER, Colors } from '../../utils/utilities';

const RankPage = () => {
    const navigate = useNavigate()
    const [init, setInit] = React.useState(false)
    const [Ranks, setRanks] = React.useState([])

    const GetLeaderBoards = async () => {
        try {
            const result = await HTTPUsersGetAll({ page: 1 })
            setRanks(result.data.data.users)
        } catch (error) {
            console.log(error)
        }
    }

    React.useEffect(() => {
        const Initial = () => {
            GetLeaderBoards()
        }
        Initial()
    }, [init])

    return (
        <div style={{ ...CENTER, width: '100%' }}>
            <div style={{
                width: '100%',
                maxWidth: '768px',
                backgroundColor: '#000',
                backgroundImage: `url(${Assets.image.background2})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundAttachment: 'fixed'
            }}>
                <div style={{ width: '100%', ...CENTER, backgroundColor: '#00000050', minHeight: '100vh', position: 'relative' }}>
                    <Stack direction={'column'} sx={{ width: '90%', height: '100%' }} alignItems={'center'} paddingY={5} spacing={5}>
                        <Stack direction={'column'} spacing={3} alignItems={'center'} width='100%'>
                            <img src={Assets.icon.drxLogoText} style={{ width: '60%', height: 'auto', objectFit: 'contain' }} alt="" />
                            <div style={{
                                width: '100%',
                                border: `1px solid ${Colors.MAIN}`,
                                backgroundColor: '#ffffff30',
                                backdropFilter: 'blur(5px)',
                                WebkitBackdropFilter: 'blur(5px)',
                                ...CENTER,
                                padding: '20px 0',
                                borderRadius: 20
                            }}>
                                <Stack direction={'column'} spacing={1} width={'90%'} alignItems={'center'}>
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                                        <Typography variant="body1" color="#fff" textAlign={'center'}>Total Players</Typography>
                                        <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ border: '1px solid #ffffff50', borderRadius: 50, padding: '3px 8px' }}>
                                            <img src={Assets.icon.person} style={{ width: 20, height: 'auto', aspectRatio: 1, objectFit: 'contain' }} alt="" />
                                            <Typography variant="caption" color={'#fff'}>{Ranks.length}</Typography>
                                        </Stack>
                                    </Stack>
                                    <br />
                                    {
                                        Ranks.map((item, index) => (
                                            <TopRankCard item={item} key={index} index={index + 1} />
                                        )).slice(0, 5)
                                    }
                                    {/* {
                                        Ranks.length > 3 ?
                                            Ranks.map((item, index) => (
                                                <RankCard item={item} key={index} index={index + 1} />
                                            )).slice(3)
                                            : null
                                    } */}
                                </Stack>
                            </div>
                        </Stack>
                        <div style={{ width: '25%', height: 'auto', aspectRatio: 4 / 3 }}></div>
                    </Stack>
                </div>
                <BottomNavigation activePage={'Rank'} navigation={navigate} />
            </div>
        </div>
    )
}

export default RouterTransitions(RankPage);