import { Tab, Tabs } from "@mui/material"
import { CENTER, Colors } from "../../utils/utilities"

export const MenuTabs = (props: { activeTab: number, onChangeTab: any }) => {
    return (
        <div style={{ color: Colors.SUBMAIN, width: '100%', ...CENTER }}>
            <Tabs
                value={props.activeTab}
                onChange={(e, value) => props.onChangeTab(value)}
                sx={{
                    backgroundColor: '#ffffff30',
                    border: '1px solid #ffffff80',
                    borderRadius: 50,
                    padding: 1,
                    backdropFilter: 'blur(5px)',
                    WebkitBackdropFilter: 'blur(5px)',
                }}
                textColor={'inherit'}
                TabIndicatorProps={{
                    sx: {
                        backgroundColor: Colors.MAIN,
                        borderRadius: 50,
                        color: Colors.SUBMAIN,
                        height: '100%',
                        zIndex: 0
                    },
                }}
            >
                <Tab
                    TouchRippleProps={{ style: { borderRadius: 50 } }}
                    label="Quest List"
                    value={0}
                    sx={{ color: props.activeTab === 0 ? Colors.SUBMAIN : '#fff', zIndex: 3 }}
                />
                <Tab
                    TouchRippleProps={{ style: { borderRadius: 50 } }}
                    label="Complete Quests"
                    value={1}
                    sx={{ color: props.activeTab === 1 ? Colors.SUBMAIN : '#fff', zIndex: 3 }}
                />
            </Tabs>
        </div>
    )
}