import { AxiosMethod } from "../utils/interceptors";

export function HTTPPointGet(params: { user_id: string }): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().get(
        `point/get?hash=_drX404&id=${params.user_id}`
      );
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}

export function HTTPPointStore(params: {
  user_id: string;
  point: number;
}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await AxiosMethod().post("point/store?hash=_drX404", {
        id: params.user_id,
        point: params.point,
      });
      return resolve(response);
    } catch (error: any) {
      return reject(error);
    }
  });
}
